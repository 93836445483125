
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      order_deatiles: [],

      Orders: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchOrders: null,
      OrdersSearchMode: false,
      searchModel: "",
      state: state
    };
  },
  methods: {
    addOrders() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addOrders",
        inputs: [
          { model: "custmor_id", type: "text", label: "custmor_id" },
          { model: "location", type: "text", label: "location" },
          { model: "total", type: "text", label: "total" },
          { model: "status_id", type: "text", label: "status_id" },
          {
            model: "order_deatiles",
            type: "select",
            options: (() => {
              return this.order_deatiles.map(data => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.order_deatiles"
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.post("orders", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    editOrders(app) {
      var data = {
        title: "popups.orders",
        inputs: [
          {
            model: "custmor_id",
            type: "text",
            label: "custmor_id",
            value: app.custmor_id
          },
          {
            model: "location",
            type: "text",
            label: "location",
            value: app.location
          },
          { model: "total", type: "text", label: "total", value: app.total },
          {
            model: "status_id",
            type: "text",
            label: "status_id",
            value: app.status_id
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.put("orders", app.id, obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    updateOrders(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "custmor_id", type: "text", label: "custmor_id" },
          { model: "location", type: "text", label: "location" },
          { model: "total", type: "text", label: "total" },
          { model: "status_id", type: "text", label: "status_id" },
          {
            model: "order_deatiles",
            type: "select",
            options: (() => {
              return this.order_deatiles.map(data => {
                return { value: data.id, label: data.id };
              });
            })(),
            label: "popups.order_deatiles"
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.emp_id = app.id;
              this.http.do("orders", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    search() {
      this.OrdersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("orders/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: ""
        })
        .then(res => {
          this.Orders = res.data;
          console.log("######## files", this.Orders);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.OrdersSearchMode = false;
      this.get(this.page);
    },

    getOrders() {
      this.http.get("orders").then(res => {
        this.Orders = res.data;
      });
    },
    deleteOrders(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage"
      };
      this.popup.confirm(data).then(resp => {
        if (resp) {
          console.log("###");
          this.http.delete("orders", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      
      this.http
        .post("users/vendors/services", {
          limit: this.limit,
          page: page,
          vendor_id:this.$route.params.id,
        })
        .then(res => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Orders = res.data;
        });
    }
  },
  created() {
    this.get(1);
    // this.getOrders();
  }
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.orders.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">{{ $t('orders.id') }}</th>
          <th scope="col">{{ $t('orders.details') }}</th>
          <th scope="col">{{ $t('orders.custmor_id') }}</th>
          <th scope="col">{{ $t('orders.location') }}</th>
          <th scope="col">{{ $t('orders.total') }}</th>
          <th scope="col">{{ $t('orders.status_id') }}</th>
          <th scope="col">{{ $t('orders.created') }}</th>
          <th scope="col">{{ $t('orders.updated') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app,index) in Orders" :key="app" class="text-center">
          <td>
            <router-link :to="'/order-deatiles/' + app?.id"># {{ index+1}}</router-link>
          </td>
           <td>
            <router-link :to="'/order-deatiles/' + app?.order.id">{{ app?.order_details }}</router-link>
          </td>
          <td>
            <router-link :to="'/order-deatiles/' + app?.order.id">{{ app?.order?.user_address?.user?.name }}</router-link>
          </td>
          <td>
            <router-link
              :to="'/order-deatiles/' + app?.order.id"
            >{{ app?.order.user_address?.address }}</router-link>
          </td>
          <td>
            <router-link :to="'/order-deatiles/' + app?.order.id">
              {{ (parseInt(app?.order.order_price)) - (parseInt(app?.order.discount_price)) +
              parseInt(app?.order.tip) }}
            </router-link>
          </td>
          <td>
            <router-link :to="'/order-deatiles/' + app?.order.id">{{ app?.order.status?.status_ar }}</router-link>
          </td>
          <td>{{ app?.order.created.split('T')[0] }}</td>
          <td>{{ app?.order.updated.split('T')[0] }}</td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="get(p)">
            {{
            p
            }}
          </a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




